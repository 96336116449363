<template lang="pug">
  div
    v-navigation-drawer.sidebar(app, dark, clipped, v-model='getDrawer', color='primary', width='250px')
      .d-flex.align-center.pt-2.pr-2(v-if='drawer')
        v-btn(
          @click.stop='getDrawer = !getDrawer'
          fab,
          x-small,
          color='#434343',
          style='box-shadow: none; background: none',
          elevation='0'
        )
          v-icon(color='#fff') menu
        img.logo(src='@/assets/img/radar-ooh.svg')
      slot(name='sidebar')
    slot(name='header')
    v-container
      slot(name='content')
</template>
<script>
import {mapGetters, mapMutations} from 'vuex'
import {TOGGLE_DRAWER} from '@/store/const/request'

export default {
  computed: {
    ...mapGetters(['drawer']),
    getDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.TOGGLE_DRAWER(value);
      },
    },
  },
  methods: {
    ...mapMutations([TOGGLE_DRAWER])
  }
};
</script>
<style scoped>
.logo {
  display: inline-block;
  margin: 0 auto 0 5px;
  height: 18px;
  padding: 0;
}
</style>
