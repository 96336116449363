<template lang="pug">
	skelet
		navHeader(slot='header', interface_name='account')
		ul#sidebar-scroll.menu.menu-nav(slot='sidebar')
			li.menu-item(:class='{ active: $route.name === "radar-campaigns" }' @click='$router.push("/radar/")')
				a
					v-icon(dark, small, left) work_outline
					| Рекламные кампании
			li.menu-item(:class='{ active: $route.name === "radar-campaign-create" }' @click='$router.push("/radar/campaign/create/")')
				a
					v-icon(dark, small, left) create_new_folder
					| Создание кампании
			li.menu-item(:class='{ active: $route.name === "radar-creatives" }' @click='$router.push("/radar/creatives/")')
				a
					v-icon(dark, small, left) image
					| Креативы
			li.menu-item(:class='{ active: $route.name === "radar-inventory" }' @click='$router.push("/radar/inventory/")')
				a
					v-icon(dark, small, left) list
					| Инвентарь
			li.menu-item(:class='{ active: $route.name === "radar-reports" }' @click='$router.push("/radar/reports/")' v-if='!$checkPermission("guest", $store)' )
				a
					v-icon(dark, small, left) mdi-signal-cellular-3
					| Отчёты
		div.padding_content(slot='content')
			router-view
			SelfInfo(:dialogFormVisible.sync='dialogFormVisible')
</template>

<script>
import SelfInfo from '@/components/SelfInfo';
import navHeader from '@/components/nav/header';
import skelet from '@/components/nav/skelet';
import {mapActions} from 'vuex';
import {CHECK_SESSION} from '@/store/const/auth';
import loading from '@/mixins/loading_mixin';
import {PROFILE} from '@/store/const/root';

export default {
	name: 'LayoutRadar',
	components: {
		navHeader,
		SelfInfo,
		skelet
	},
	mixins: [loading],
	data: function () {
		return {
			dialogFormVisible: false,
		};
	},
	beforeMount() {
		this.InitData();
	},
	methods: {
		...mapActions('Auth', [CHECK_SESSION]),
		...mapActions('Root', [PROFILE]),
		async InitData() {
			await this.CHECK_SESSION()
			if (!this.isError(CHECK_SESSION)) {
				this.PROFILE('account')
			}
			
		},
	},
};
</script>

<style>
.container {
	max-width: inherit;
}

.v-app-bar.v-app-bar--fixed {
	position: fixed;
	top: 0;
	z-index: 5;
	
	height: 45px;
	margin-top: 0px;
	transform: translateY(0px);
	left: 0px;
	right: 0px;
	background-color: rgb(67, 67, 67);
	border-color: rgb(67, 67, 67);
}

.menu-item {
	padding: 6px 0 !important;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.loading {
	color: transparent !important;
	min-height: 0.8rem;
	pointer-events: none;
	position: relative;
}

.loading::after {
	animation: loading 0.5s infinite linear;
	background: 0 0;
	border: 2px solid #5755d9;
	border-radius: 50%;
	border-right-color: transparent;
	border-top-color: transparent;
	content: '';
	display: block;
	left: 50%;
	margin-left: -0.4rem;
	margin-top: -0.4rem;
	opacity: 1;
	padding: 0;
	position: absolute;
	top: 50%;
	width: 20px;
	height: 20px;
	z-index: 1;
}

@keyframes loading {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>

